import React, {useEffect, useState} from "react";
import { Box, Collapse, ListItemIcon } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {
    clinicsRoutes,
    clinicConfigurationRoutes,
    clinicsAddEdit,
    clinicCrmConfigurationRoutes,
    clinicsCrmRoutes,
    clinicsCrmAddEdit,
    clinicsStaffRoutes, clinicsStaffAddEdit,
} from "../../../routes/clinicsRoutes";
import { HiOutlineInbox } from "react-icons/hi";
import Slide from "react-reveal/Slide";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../components/loader/loader";
import {getClinicByID} from "../../../store/clinics";

const Index = ({ clinicId }) => {
    const [open, setOpen] =useState(0);
    const [loading, setLoading] =useState(false);

    const navigate = useNavigate();
    const params = useParams()
    const dispatch = useDispatch()
    const location = useLocation();
    const {pathname} = location;


    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)
    const user = useSelector((state) => state?.authStore?.user);
    let clinicsFinalRoutes, clinicFinalConfigurationRoutes, clinicsFinalAddEdit;

    if (user?.role_id === 2) {
        // Assign CRM specific routes for role_id 2
        clinicsFinalRoutes = clinicsCrmRoutes;
        clinicFinalConfigurationRoutes = clinicCrmConfigurationRoutes;
        clinicsFinalAddEdit = clinicsCrmAddEdit;
    } else if (user?.role_id === 4) {
        // Assign CRM specific routes for role_id 2
        clinicsFinalRoutes = clinicsStaffRoutes;
        clinicFinalConfigurationRoutes = clinicCrmConfigurationRoutes;
        clinicsFinalAddEdit = clinicsStaffAddEdit;
    } else {
        // Default routes for other roles
        clinicsFinalRoutes = clinicsRoutes;
        clinicFinalConfigurationRoutes = clinicConfigurationRoutes;
        clinicsFinalAddEdit = clinicsAddEdit;
    }

    useEffect(() => {
        clinicFinalConfigurationRoutes.map((item) => {
            if (pathname.includes(item?.active)) {
                setOpen(1);
            }
        });
    }, [pathname]);

    const handleButtonClick = (val) => {
        navigate(val);
    };
    const handleClick = (val) => {
        setOpen(val === open ? 0 : val);
    };

    useEffect(()=>{
       if (clinicData?.id){
           setLoading(false)
       }else {
           setLoading(true)
           if (params?.clinic_id && !clinicData?.id){
               dispatch(getClinicByID(params?.clinic_id))
           }
       }

    },[clinicData,clinicData?.id])
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                height: "calc(100vh - 155px)",
                backgroundColor: "#EDF2F7",
            }}
        >
             {pathname !== `/crm/configuration/clinic/${clinicId}/scheduler` && (
            <Slide left>
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        mr: "30px",
                        width: "245px",
                        borderRadius: "7px",
                        overflowY: "auto",
                        height: "100%",
                        display: { md: "block", xs: "none", sm: "none", lg:"block" },
                        "&::-webkit-scrollbar": {
                            width: "10px",
                            height: "10px"
                          },
                          "&::-webkit-scrollbar-track": {
                            background: "#fff"
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "4px",
                            background: "rgba(0, 0, 0, 0.2)",
                            boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
                          }
                    }}
                >
                    <List
                        sx={{
                            width: "100%",
                            px: "0px",
                            ".MuiListItemIcon-root ": {
                                minWidth: "37px",
                            },
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {user?.role_id !== 4 && (
                            <>
                        <ListItemButton
                            onClick={() => handleClick(1)}
                            sx={{
                                borderBottom: "1px solid #dee2e6",
                            }}
                        >
                            <ListItemIcon> <HiOutlineInbox/> </ListItemIcon>
                            <ListItemText primary="Configuration"/>
                            {open === 1 ? <MdExpandLess/> : <MdExpandMore/>}
                        </ListItemButton>
                        <Collapse in={open === 1} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {clinicFinalConfigurationRoutes.map((item, index) => (
                                    <ListItemButton
                                        key={index}
                                        onClick={() =>
                                            handleButtonClick(item?.url + clinicId + item?.active)
                                        }
                                        sx={{
                                            // borderRadius:'7px',
                                            pr: "  7px",
                                            pl: "  30px",
                                            borderBottom: "1px solid #dee2e6",
                                            backgroundColor: pathname.includes(item?.active)
                                                ? "#C9EDFC"
                                                : "#fff",
                                            "&:hover": {
                                                backgroundColor: "#C9EDFC",
                                            },
                                        }}
                                    >
                                        <ListItemText primary={item?.name}/>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                         </>
                        )}
                        {clinicsFinalRoutes.map((item, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => {
                                    handleButtonClick(item?.url + clinicId + item?.active);
                                    setOpen(0);
                                }}
                                sx={{
                                    // borderRadius:'7px',
                                    py: "7px",
                                    borderBottom: "1px solid #dee2e6",
                                    backgroundColor: pathname.includes(item?.active)
                                        ? "#C9EDFC"
                                        : "#fff",
                                    "&:hover": {
                                        backgroundColor: "#C9EDFC",
                                    },
                                }}
                            >
                                <ListItemText primary={item?.name}/>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Slide>
             )}
            <Box
                sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    borderRadius: "7px",
                    height: "100%",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                        width: "10px",
                        height: "10px"
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "#fff"
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "4px",
                        background: "rgba(0, 0, 0, 0.2)",
                        boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
                      }
                }}
            >
                {loading ? <Loader/> :
                    [...clinicsFinalRoutes, ...clinicFinalConfigurationRoutes,...clinicsFinalAddEdit].map(
                        (item, index) =>
                                pathname.includes(item?.active) && (
                                <Box key={index}>{item?.components}</Box>
                            )
                    )
                }
            </Box>
        </Box>
    );
};

export default Index;
