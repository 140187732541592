import React, {useEffect, useState} from 'react';
import {Box, useMediaQuery} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { useTheme } from '@mui/material/styles'
import {fetchChatsContacts, fetchUserProfile, removeSelectedChat, selectChat, sendMsg} from "../../../store/crm/chat";
import SidebarLeft from "../../../components/chat/sidebarLeft";
import ChatContent from "../../../components/chat/chatContent";

const isToday = date => {
    const today = new Date()
    return (
        new Date(date).getDate() === today.getDate() &&
        new Date(date).getMonth() === today.getMonth() &&
        new Date(date).getFullYear() === today.getFullYear()
    )
}
export const getInitials = string => string.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '')
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }
    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}


const Chat = () => {
    const [userStatus, setUserStatus] = useState('online')
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false)
    const [userProfileLeftOpen, setUserProfileLeftOpen] = useState(false)
    const [userProfileRightOpen, setUserProfileRightOpen] = useState(false)


    const theme = useTheme()
    const dispatch = useDispatch()
    const hidden = useMediaQuery(theme.breakpoints.down('lg'))
    const store = useSelector(state => state.chat)

    const smAbove = useMediaQuery(theme.breakpoints.up('sm'))
    const sidebarWidth = smAbove ? 370 : 300
    const mdAbove = useMediaQuery(theme.breakpoints.up('md'))

    const statusObj = {
        busy: 'error',
        away: 'warning',
        online: 'success',
        offline: 'secondary'
    }

    useEffect(() => {

        dispatch(fetchUserProfile())
        dispatch(fetchChatsContacts())
    }, [dispatch])

    const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen)
    const handleUserProfileLeftSidebarToggle = () => setUserProfileLeftOpen(!userProfileLeftOpen)
    const handleUserProfileRightSidebarToggle = () => setUserProfileRightOpen(!userProfileRightOpen)
    return (
      <Box
      sx={{
          width: '100%',
          display: 'flex',
          borderRadius: 1,
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'background.paper',
          height: `calc(100vh - 109px)`,
      }}
      >

          <SidebarLeft
              store={store}
              hidden={hidden}
              mdAbove={mdAbove}
              dispatch={dispatch}
              statusObj={statusObj}
              userStatus={userStatus}
              selectChat={selectChat}
              getInitials={getInitials}
              sidebarWidth={sidebarWidth}
              setUserStatus={setUserStatus}
              leftSidebarOpen={leftSidebarOpen}
              removeSelectedChat={removeSelectedChat}
              userProfileLeftOpen={userProfileLeftOpen}
              formatDateToMonthShort={formatDateToMonthShort}
              handleLeftSidebarToggle={handleLeftSidebarToggle}
              handleUserProfileLeftSidebarToggle={handleUserProfileLeftSidebarToggle}
          />
          <ChatContent
              store={store}
              hidden={hidden}
              sendMsg={sendMsg}
              mdAbove={mdAbove}
              dispatch={dispatch}
              statusObj={statusObj}
              getInitials={getInitials}
              sidebarWidth={sidebarWidth}
              userProfileRightOpen={userProfileRightOpen}
              handleLeftSidebarToggle={handleLeftSidebarToggle}
              handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}
          />

      </Box>
    );
};

export default Chat;